* {
  margin: 0;
  padding: 0;
}
.geral{
  width: 100vw;
  height: 100%;
}
.over{
  height: 60vh;
  overflow-y: auto;
}
.product-list{
  height: 100%;
}
table{
  width: 100%;
}
thead{
  position: sticky;
  top: 0;
}

.border-top-01{
  width: 30%;
  margin: 10px;
  font-size: 15px;
  color: black;
  background-color: lightslategrey;
  border-style: groove;
}

.border-top-02{
  width: 5%;
  margin: 10px;
  font-size: 15px;
  color: black;
  background-color: lightslategrey;
  border-style: groove;
}

.border-top-03{
  width: 8%;
  margin: 10px;
  font-size: 15px;
  color: black;
  background-color: lightslategrey;
  border-style: groove;
}

.border-top-04{
  width: 16%;
  margin: 10px;
  font-size: 15px;
  color: black;
  background-color: lightslategrey;
  border-style: groove;
}

.border-top-05{
  width: 5.5%;
  margin: 10px;
  font-size: 15px;
  color: black;
  background-color: lightslategrey;
  border-style: groove;
}

textarea{
  height: 25vh;
  font-size: 15px;
  box-sizing: border-box;
}

.border-top-06{
  width: 29%;
  margin: 10px;
  font-size: 15px;
  color: black;
  background-color: lightslategrey;
  border-style: groove;
}

.border-top-07{
  width: 8%;
  margin: 10px;
  font-size: 15px;
  color: black;
  background-color: lightslategrey;
  border-style: groove;
}
div>.op3{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: #0e5392;
    
    text-decoration: none;
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
    
}
div>.op4{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: #0e5392;
    text-decoration: none;
    font-size: 18px;
  
}

div>.op4:hover{
  color: white;
}

.card-header{
  padding-top: 5px;
  width: 100%;
  font-size: 20px;
  background: linear-gradient(rgba(228, 235, 241, 0.925), rgba(56, 185, 224, 0.623)), url(assets/barra\ cotação\ cima.png);
  height: 30px;
  padding-left: 20px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.placeholder{
  border-style: solid;
  border-width: 1px;
  font-size: 15px;
  color: black;
}

.edit-input{
  cursor: pointer;
  width: 70px;
  border-style: solid;
  border-width: 1px;
  font-size: 17px;
  color: black;
}

td{
  background-color: white;
}

.placeholder02{
  border-style: solid;
  border-width: 1px;
  font-size: 17px;
  color: black;
}

.placeholder03{
  border-style: solid;
  border-width: 1px;
  font-size: 17px;
  color: black;
}

.placeholder04{
  border-style: solid;
  border-width: 1px;
  font-size: 17px;
  color: black;
}

.OBS{
  border-radius: 10px;
  border-style: solid;
}

.placeholder05{
  cursor: pointer;
  width: 425px;
  border-style: solid;
  border-width: 1px;
  font-size: 17px;
  color: black;
  background-color: white;
}

.placeholder06{
  border-style: solid;
  border-width: 1px;
  font-size: 17px;
  color: black;
}

.text-danger{
  margin-left: 45vw;
  display: grid;
  flex-direction: row;
  justify-content: center;
  font-size: 20px;
  text-align: center;
  color: red;  
}

i{
  height: 6vw;
}

.home-image{
  margin-left: 5px;
  width: 150px;
  padding-right: 30px;
}

.geral-header{  
  width: 100%;
  height: 80px;
  color: #87CEFA;
}

.container{
  display: flex;
}

.marca{
  margin-left: 450px;
  width: 500px;
  color: #000;
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 15px;
  margin-right: 5px;
}

.cnpjText{
  font-size: 12px;
}

.exit{
  background: url(assets/sair.png);
  cursor: pointer;
  margin-right: 25px;
  border: none;
  color: white;
  height: 25px;
  padding: 3px 43px;
}

select{
  cursor: pointer;
  border-color: #9cc4e4;
  border-top-width: 5px;
  color: #333333;
  font-family: Yu Gothic;
  font-size: 17px;
  height: 10px;
  width: 100%;
  border-radius: 10px;
  height: auto;
  align-items: center;
  border-width: 2.5px;
}

select:hover{
  color: #005bc5;
  background-color: #eee8e8;
} 

select:disabled{
  color:darkgray;
  background-color: #333333;
}

.botao{
  margin-top: 10px;
  display: flex;
  justify-content: center;
  padding-right: 5px;
  margin-bottom: 2vw;
}

.save{ 
  margin-top: 0px;
  cursor: pointer;
  border: none;
  background: url(assets/salvar.png);
  color: #f7f7f7;
  width: 50px;
  height: 50px;
}


.edit{
  margin-top: 0px;
  cursor: pointer;
  border: none;
  background: url(assets/editar.png);
  color: #f7f7f7;
  width: 50px;
  height: 50px;
  margin-bottom: 2px;  
}

.leganda{
  justify-content: left;
  display: flexbox;
}

.up{
  margin-right: 10px;
  margin-top: 0px;
  height: 24px;
  background: url(assets/acima.png);
  width: 64px;
}

.down{
  margin-right: 1vw;
  height: 24px;
  margin-top: 3px;
  background: url(assets/abaixo.png);
  width: 64px;
}

.a{
  display: flex;
  flex-direction: row;
}

@media(max-width:700px){
  .over{
    overflow-y: scroll;
    overflow-x: scroll;
  }
  .table-resposive{
    width: 95%;
  }
  .edit{
    margin-top: 0px;
  }
  .up{
    margin-top: 1px;
  }
  .save{
    margin-top: 0px;
  }
  .placeholder05{
    height: '4vh'
  }
  .marca{
    margin-left: 50px;
  }
}

@media(max-height:500px){
  .edit{
    margin-top: 2px;
  }
  .up{
    margin-top: 2px;
  }
  .save{
    margin-top: 0px;
  }
}

@media(max-width:700px){
  .cnpjText{
    visibility: hidden;
    font-size: 1px;
  }
}
