 
*{
  
  margin: 0px;
  padding: 0px;
  
}

.cnpj{
  font-size: 13px;
}

.geral{
  width: 100%;
}

.app-header3 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  height: 80px;
  color: #dadee0;
}

.content{
  display: flex;
  margin-right: 5px;
}

.app-title2 {
  display: contents;
  font-size: 50px;
  text-shadow: 
  -1px -1px 0px #2BBDF2, 
  -1px 1px 0px #2BBDF2,                    
   1px -1px 0px #2BBDF2,                  
   1px 0px 0px #2BBDF2;
  
}
.op1, op2{
  padding: 20px;
}

.btns{
  padding-top: 5px;
  width: 100%;
  font-size: 20px;
  background: linear-gradient(rgba(228, 235, 241, 0.925), rgba(56, 185, 224, 0.623)), url(assets/barra\ cotação\ cima.png);
  height: 30px;
  padding-left: 20px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}  

.Ver{
  text-align: center;
  background-color: #e9e9e9;
  box-shadow:  2px 2px 2px 1px gray;
  color: rgba(19, 18, 18, 0.979);
  font-size: 0.3em;
  left: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
}

.texto{
  width: 100%;
  margin: auto;
  background-color: #FAE475;
  
  color: #FAE475;
  font-size: 20px;
  text-shadow: 
  -1px -1px 0px #ED9F02, 
  -1px 1px 0px #ED9F02,                    
   1px -1px 0px #ED9F02,                  
   1px 0px 0px #ED9F02;
}

.icon{
  height: 25px;
  width: 30px;
  color: #ED9F02;
}

/*box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);*/

.imageHome{
    margin-left: 8px;
    max-width: 150px;
    padding-right: 20px;
    padding-left: 0px;
  }

.op1 {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #59C2D4;
    text-decoration: none;
    text-shadow: 
    -1px -1px 0px #6CA0C7, 
    -1px 1px 0px #6CA0C7,                    
     1px -1px 0px #6CA0C7,                  
     1px 0px 0px #6CA0C7;
  
}

.op2 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #59C2D4;
    text-decoration: none;
    text-shadow: 
    -1px -1px 0px #6CA0C7, 
    -1px 1px 0px #6CA0C7,                    
     1px -1px 0px #6CA0C7,                  
     1px 0px 0px #6CA0C7;
}

Link{
  color: #59C2D4;
}

.Brand{
  margin-right: 3px;
  margin-left: auto;
  color: #000;
 
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 15px;
  font-weight: bold;
  
}

.link{
  margin-right: 10px;
  cursor: pointer;
  background: url(assets/sair.png);
  border: none;
  padding: 3px 43px;
  text-decoration: none;
  height: 26px;
  margin-left: 5px;
}

.icon2{
  margin-left: 7px;
  color: seagreen;
  height: 30px;
  width: 30px;
}

@media(max-width:700px){
  h1{
   visibility: hidden;
  }
  .app-title2{
    font-size: 10px;
  }
  .texto{
    width: 105%;
  }
}

@media(max-width:500px){
  .cnpj{
    visibility: hidden;
  }
}
    
  
