body{
  background-image: url(assets/fundoLogin.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.login-header {
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

button{
  height: 50px;
}

.box{
  
  display: flexbox;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 350px;
  border-radius: 10px;
  overflow: hidden;
  padding: 70px 50px 30px 50px;
  box-shadow: 0 5px 10px 0px rgba(5, 5, 5, 0.952);
}

.login-message{
  color: dodgerblue;
}

.login-error{
  color: red;
  font-size: medium;
}

.image{
  margin-left: 67px;
  width: 200px;
  padding-bottom: 20px;
}

.wrap-input{
  border-color: #2BBDF2;
  width: 100%;
  height: 50px;
  position: relative;
  border-radius: 10px;
  border-style: solid;
}

.btn{
  border-color: #2BBDF2;
  font-weight: bold;
  letter-spacing: 3px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  font-weight: 50px;
  font-size: 20px;
  background-color: cornflowerblue;
  text-shadow: 
               -1px -1px 0px #2BBDF2, 
               -1px 1px 0px #2BBDF2,                    
                1px -1px 0px #2BBDF2,                  
                1px 0px 0px #2BBDF2;
}

.wrap-input::before{
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  background: #0423ee;
  background: -webkit-linear-gradient(to left, #21d4fd, #91bdff);
  background: -o-linear-gradient(to left, #21d4fd, #91bdff);
  background: -moz-linear-gradient(to left, #21d4fd, #91bdff);
  background: linear-gradient(to left, #21d4fd, #91bdff);
}

